<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">We ♥ Crypto and Gaming!</h1>
    </div>


<p> No, we aren't backed by some big VCs. We are everyday people just like you with big dreams!</p> 
<p>Invest in us and help us make our dreams a reality!</p> 
<p>And of course, we will not forget about initial 10,000 NFT Founders and will ensure exclusive rewards! </p> 

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Meet The Team</h1>

    </div>
<p> Our reveal will be announced after Phase 1 of Founder NFT Sales.</p> 
  <vs-card-group>

  <vs-card>
    <template #title class="team-text">

      <h3>ENCORE</h3>
    </template>
    <template #img>
      <img src="/encore.png" alt="">
    </template>
    <template #text class="team-text">
      <p>
        <h7>
CTO</h7>      </p>
           <p>
        <h8>
        Avid Crypto and Blockchain enthusiast. Started getting into Tech and Programming since his early youth. Absolutely thrilled to be working on this project!</h8>
      </p>
    </template>

  </vs-card>

  <vs-card>
    <template #title class="team-text">

      <h3>LOLABUNNY</h3>
    </template>
    <template #img>
      <img src="/lolabunny.png" alt="">
    </template>
    <template #text class="team-text">
      <p>
        <h7>
Game World Design</h7>      </p>
           <p>
        <h8>
        Gamer at heart who loves looking into innovation to constantly change the gaming landscape. Believes the play-to-earn model is the future of gaming. Why not make money while having fun?</h8>
      </p>
    </template>

  </vs-card>
  </vs-card-group>
  <vs-card-group>
  <vs-card>
    <template #title class="team-text">

      <h3>TIMESTREAM</h3>
    </template>
    <template #img>
      <img src="/timestream.png" alt="">
    </template>
    <template #text class="team-text">
      <p>
        <h7>
Head of Marketing</h7>      </p>
           <p>
        <h8>
        Digital marketing experience working with clients in various industries. When not working he can be seen playing Valorant or Dota2.</h8>
      </p>
    </template>

  </vs-card>

  <vs-card>
    <template #title class="team-text">

      <h3>SUPADOG</h3>
    </template>
    <template #img>
      <img src="/supadog.png" alt="">
    </template>
    <template #text class="team-text">
      <p>
        <h7>
Lead Community Manager</h7>      </p>
           <p>
        <h8>
        Started thinkering with computers in his early days. These days he spends time on crypto and communities on discord.</h8>
      </p>
    </template>

  </vs-card>
  </vs-card-group>

  

<p> </p>
 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  
    </div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">Supa.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Team",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}

</style>
