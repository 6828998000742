<template>

<div>
   <div v-if="isBarOpen"  @click=" onClickButton" class="navbar-backdrop" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"></div>

<header class="navbar-padding">
      <vs-navbar color="#121b35" text-white square center-collapsed v-model="active">
        <template #left><a href="/" class="logo-link">
        <img src="/logo1.png" alt="" height="40px" width="40px"></a>
           <a href="/" class="logo-link">       <h5>SUPA FOUNDATION</h5>
              </a>
 
        </template>
         

       
        <template #right>
           <vs-button color="#5ac4bd" border @click="onClickButton" class="d-md-none collapsed" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
  <i class='bx bx-menu '></i>
         </vs-button>
         
                   <vs-button color="twitter" href="https://twitter.com/supafoundation"  icon><i class='bx bxl-twitter ' ></i></vs-button>

         <vs-button color="discord" href="https://discord.gg/7zP4fs3U3N" icon  ><i class='bx bxl-discord' ></i></vs-button>


            
          <vs-button color="#5ac4bd" href="#" hidden border v-if="!isUserConnected" @click="connectWeb3Modal" ><i class='bx bx-plus'></i>Wallet</vs-button>
        <vs-button color="#5ac4bd" href="#" border v-if="isUserConnected" @click="disconnectWeb3Modal" >Disconnect {{getActiveAccount.substring(0, 7)}}...</vs-button>

        </template>
      </vs-navbar>
   
</header>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters("accounts", ["getActiveAccount", "isUserConnected", "getWeb3Modal"]),
  },
 
  created() {
    this.$store.dispatch("accounts/initWeb3Modal");
    this.$store.dispatch("accounts/ethereumListener");
  },
  
  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
    onClickButton () {
            this.isBarOpen=!this.isBarOpen

      this.$emit('childToParent', this.isBarOpen)
      console.log("emitting")
    }
  },
   data:() => ({
      active: 'start',
      isBarOpen:false,
    })
}
</script>


<style scoped>
.navbar-backdrop {
  z-index: 100001;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  bottom: 0;
}
</style>