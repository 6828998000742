<template>
  <div>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">About SupaFoundation</h1>
    </div>
<p>In a distant future, Earth is plagued by mutated versions of the Coronavirus. It is up to humanity's last hope, Supreme Leader Musk and the Supa Foundation, to combat the virus. </p>
    <p>Join the Supa Foundation to save humanity in this upcoming NFT-based play to earn game! </p>
    <p>Supa Foundation is an NFT based PVP-style play to earn game set in an alternate futuristic expansive metaverse.</p>
    <p>The first NFT of 5000 limited-edition virus strains will be launched soon.</p>
        <p>We look forward to seeing you on our Discord</p>

<button type="button" @click="gotoGiveaway" class="btn btn-success btn-space">Proceed to Giveaway</button>

 <p class="mars-banner"><img src="virusbanner.jpg"></p>

<p> </p>
 

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">Supa.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Giveaway",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
  methods:{
    gotoGiveaway(){
        window.open("https://socialman.net/c/6PnkE/");
      

    }
  }
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.btn-space {
  margin: 10px;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
</style>
