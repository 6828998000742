<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Welcome, Recruit!</h1>
    </div>

    <p>In a distant future, Earth is plagued by mutated versions of the Coronavirus. It is up to humanity's last hope, Supreme Leader Musk and the Supa Foundation, to combat the virus. </p>
    <p>Join the Supa Foundation to save humanity in this upcoming NFT-based play to earn game! </p>
    <p>Start collecting SupaFuel tokens in our BETA <a href="https://app.supa.foundation" target="_blank">App</a>! Join the storyline and community on <a href="https://discord.com/invite/7zP4fs3U3N" target="_blank">Discord</a> and <a href="https://twitter.com/supafoundation" target="_blank">Twitter</a>.</p>
    <p><a class="app-link" target="_blank" href="https://app.supa.foundation"><img src="pwa.png"></a></p>
    <p v-if="isUserConnected">
      <strong>Your current chain:</strong> {{getChainName}}
    </p>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Overview</h1>
    </div>

    <p>Supa Foundation is an NFT based PVP-style play to earn game set in an alternate futuristic expansive metaverse.</p>
    <p>The first NFT of 5000 limited-edition virus strains will be launched soon.</p>
    <p>You can read more about this in our About, RoadMap and FAQ section</p>
    <p>In the meantime, follow Supa Foundation's storyline on <a href="https://discord.com/invite/7zP4fs3U3N" target="_blank">Discord</a> and install the <a href="https://app.supa.foundation" target="_blank">App</a> to collect SupaFuel Tokens</p>
    <p v-if="isUserConnected">
      <strong>Your current chain:</strong> {{getChainName}}
    </p>
<p> </p>
<p> </p>
<p> </p>
<p> </p>
    <p class="mars-banner"><img src="mars.jpeg"></p>

<p> </p>
 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  
    </div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">Supa.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
</style>
