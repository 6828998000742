<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">The NFTs</h1>
    </div>

<h3 class="h3">  The intial Founder's-edition unique NFTs will be launched in 2 Phases:   </h3> 
<p> 1) 5000 unique viruses.  </p> 
<p> 2) 5000 unique nanomeds.  </p>
<p> 100 NFTs for each of the 2 Phases will be reserved for promotion and giveaways and 900 NFTs each will be reserved for hardcore community members for pre-sale purchase.  </p>  
 <p class="mars-banner"><img src="virusbanner.png"></p>

<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Founder NFT Benefits</h1>
    </div><p>1) Lifelong early access to initial BETA releases of the game.</p> 
<p>2) Unique in-game characters that will be highly coveted in future.</p> 
<p>3) Farming boost in-game currencies for NFT holders.</p> 


  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">The Game</h1>
    </div>
<p> The game will be a Play-To-Earn mobile PVP style turn-based game. This will require users to hold at least 1 NFT character to be able to play the game. There will also be a open marketplace for users to trade in-game currencies, items and loot.  </p> 
 <p> There will be various ways for gamers to earn while enjoying the game!</p> 
 <p class="mars-banner"><img src="mars2.jpeg"></p>
<p> </p>
 

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">Supa.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "About",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
</style>
