<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Frequently Asked Questions</h1>
    </div>

<div class="accordion" id="accordionGame">
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       Only 10,000 initial NFTS? What about the rest of us who want to play the game?
      </button>
    </h6>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionGame">
      <div class="accordion-body">
       There will be initially only 10,000 exclusive Founder's NFTS (Virues & NanoMeds). Our Founder's are our initial investors who should and will be rewarded with various perks.Regular playable NFT characters will be able to be minted at a later date.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        What will the gameplay be like?
      </button>
    </h6>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionGame">
      <div class="accordion-body">
The game will be turn-based PvP style and will be Play-To-Earn. We plan to make the game competitive for all players and will not be geared towards Pay-To-Win. We have more details but will share these at a later date. Stay tuned...
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
What is the purpose of the Supa Foundation SupaFuel App?
      </button>
    </h6>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionGame">
      <div class="accordion-body">
This is our early adopter, loyalty-based App. It enables users to SupaFuel Tokens that will later be claimable in-game. Users will also be able to follow the latest developments and updates from us.
      </div>
    </div>
  </div>
    <div class="accordion-item">
    <h6 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
How will NFT resale royalties be used?
      </button>
    </h6>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionGame">
      <div class="accordion-body">
There will be a 2.5% royalty fee on NFT resales. 2% will be distributed to our team for ongoing development of the project including a proportion to the NFT artist(s). The remaining 0.5% will be paid out to a charitable cause of the community's choosing based on votes. We will be implementing a DAO for this at a later stage. Anyone with the NFT can vote.
      </div>
    </div>
  </div>
    <div class="accordion-item">
    <h6 class="accordion-header" id="headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
How is Supa Foundation contributing to charity?
      </button>
    </h6>
    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionGame">
      <div class="accordion-body">
Apart from Royalties, 10% of proceeds from all the Founder Edition NFTs sold will go towards charitable causes. You will directly be contributing by being a Supa Foundation member owning an NFT!  </div>
    </div>
  </div>
     <div class="accordion-item">
    <h6 class="accordion-header" id="headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
When will the Phase 1 Founder's Edition NFTs be relased?
      </button>
    </h6>
    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionGame">
      <div class="accordion-body">
We aim to launch in Quarter 4 of 2021. The exact date will be announced at a later time. We are currently prioritising growing and strengthening our community.
      </div>
    </div>
  </div>
     <div class="accordion-item">
    <h6 class="accordion-header" id="headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
Which blockchain will be used?
      </button>
    </h6>
    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionGame">
      <div class="accordion-body">
We plan to use an EVM compatible blockchain with low gas fees such as Polygon, Fantom or Avalanche for future Ethereum-based cross-chain compatibility.  
      </div>
    </div>
  </div>

</div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">Supa.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FAQ",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}

</style>
