<template>

 
      <vs-sidebar
       
        v-model="active"
        open
        background="#121b35"
        text-white
        >
        <template #logo>
<a href="/" class="logo-link">
        <img src="/logo1.png" alt="" ></a>
           <a href="/" class="logo-link">       <h5>SUPA FOUNDATION</h5>
              </a>        </template>
    
   
      
          <vs-sidebar-item id="start" to="/">
            <template #icon>
              <i class='bx bxs-rocket' ></i>
            </template>
            Start Adventure
          </vs-sidebar-item>
         
           <vs-sidebar-item id="about"  to="/about">
            <template #icon>
              <i class='bx bx-spreadsheet'></i>
            </template>
            About
          </vs-sidebar-item>
            <vs-sidebar-item id="charity"  to="/charity">
            <template #icon>
              <i class='bx bxs-donate-heart'></i>
            </template>
            Charity
          </vs-sidebar-item>
            <vs-sidebar-item id="litepaper" href="https://supa-foundation.gitbook.io/wiki-1/">
            <template #icon>
              <i class='bx bx-book'></i>
            </template>
            Litepaper
          </vs-sidebar-item>
          <vs-sidebar-item id="roadmap" to="/roadmap">
            <template #icon>
              <i class='bx bx-map-alt'></i>
            </template>
            Roadmap
          </vs-sidebar-item>
           <vs-sidebar-item id="Team" to="/team">
            <template #icon>
              <i class='bx bx-group'></i>
            </template>
            Team
          </vs-sidebar-item>
              <vs-sidebar-item id="FAQ" to="faq">
            <template #icon>
              <i class='bx bx-info-circle'></i>
            </template>
            FAQ
          </vs-sidebar-item>
      
       
         <vs-sidebar-item v-if="isUserConnected" id="setValue" href="/set-value">
          <template #icon>
            <i class='bx bx-edit-alt'></i>
          </template>
          SetValue
        </vs-sidebar-item>
        <vs-sidebar-item id="profile" v-if="isUserConnected" href="/profile">
          <template #icon>
            <i class='bx bxs-user'></i>
          </template>
          Profile
        </vs-sidebar-item>
      
        <template #footer >
          <vs-row hidden>
         <div class="alert alert-warning m-3" v-if="showChainAlert" role="alert">
          <span v-if="getChainName">Your currently selected chain is <strong>{{getChainName}}</strong> (a testnet).</span>

          <span v-if="!getChainName">
            Please <a href="#" @click="connectWeb3Modal" class="alert-link">connect</a> with MetaMask
            or some other Ethereum wallet.
          </span>
        </div>
          </vs-row>
        </template>
      </vs-sidebar>


</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Sidebar",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected", "getWeb3Modal"]),

    showChainAlert() {
      switch (this.getChainName) {
        case "Mainnet":
          return false;
        default:
          return true;
      }
    }
  },
  created() {
    this.$store.dispatch("accounts/initWeb3Modal");
    this.$store.dispatch("accounts/ethereumListener");
    if(this.$route.path=="/"){
      this.active='start'
    }else if((this.$route.path=="/about")){
      this.active='about'

    }else if((this.$route.path=="/roadmap")){
      this.active='roadmap'

    }
    else if((this.$route.path=="/team")){
      this.active='team'

    }
    else if((this.$route.path=="/faq")){
      this.active='faq'


    }
     else if((this.$route.path=="/charity")){
      this.active='charity'

    }
    else{
            this.active='start'

    }
  },
  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
  },
   data:() => ({
      active: 'start',
    })
}
</script>
