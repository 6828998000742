<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">First Year Roadmap</h1>
    </div>




<div class="container">



    <div class="rb-container">
      <ul class="rb">
        <li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
            Phase 1 (estimated Q4 2021)
          </div>
          <div class="item-title"><p> 1) Launch of Website, Discord and Twitter</p> 
<p> 2) Release of First Half Chapter 1 of storyline on Discord</p> 
<p> 3) Engagement with Launchpads and DEX, including KYC</p> 
<p> 4) Sales of $SUPA Token via ICO</p> 
<p> 5) $SUPA Token yield farming</p></div>

        </li>
        <li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
           Phase 2 (estimated Q4 2021 to Q1 2022)
          </div>
          <div class="item-title"><p>1) Release of Second Half Chapter 1 of storyline on Discord</p> 
<p>2) Sale NFTs</p> 
<p>3) Launch of Supa Foundation's DAO</p> 
<p>4) Launch of The Foundation's marketplace</p> 
</div>
        </li>

        <li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
Phase 3 (estimated Q2 2022 to Q3 2022)
          </div>
          <div class="item-title"><p>1) Expansion of The Foundation’s marketplace to allow fusing of NFTs</p> 
<p>2) Release Beta version of Stage 1 game</p> 
<p>3) SupaFuel will be claimable for in-game use</p> </div>

        </li>
   <li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
Phase 4 (estimated Q3 2022 to Q4 2022)
          </div>
          <div class="item-title"><p>1) Release of full version of Stage 1 game</p> 
<p>2) Early Preview into Stage 2 game development</p>
<p>3) Release of Chapter 2 of storyline on Discord</p></div>
<li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
Phase 5 (Future)
          </div>
          <div class="item-title"><p>1) Release of more in game content (cards, cosmetics, and more) </p> 
<p>2) Expansion of metaverse storyline</p>
<p>3) Migration to own blockchain</p>
<p>4) Creation of more games within the metaverse</p></div>


        </li>
      </ul>

  </div>
</div>

<p> </p>
    <p class="mars-banner"><img src="mars4.png"></p>

 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  
    </div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">Supa.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Roadmap",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>



.container {
 
  position: relative;

}




.rb-container {

  margin: auto;
  position: relative;
}

.rb-container ul.rb {
  padding: 0;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 1em;
  min-height: 50px;
  border-left: 1px dashed #fff;
  padding: 0 0 20px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 1);
  border-radius: 500%;
  background: #c51f26;;
  height: 30px;
  width: 30px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #232931;
  transition: all 1000ms ease-in-out;
}

ul.rb li .timestamp {
  color: #c51f26;
  position: relative;
  font-size: 18px;
}

.item-title {
  color: #fff;
    font-size: 18px;

}



.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
</style>
