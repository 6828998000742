import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SetValue from "./pages/SetValue";
import About from "./pages/About";
import Roadmap from "./pages/Roadmap";
import Team from "./pages/Team";
import FAQ from "./pages/FAQ";
import Charity from "./pages/Charity";
import Giveaway from "./pages/Giveaway";


Vue.use(Router);

export default new Router({
    // Make sure the server can handle the history mode
    // If not, set it to hash (or delete the mode)
    // More info here: https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/about",
            name: "about",
            component: About
        },
        {
            path: "/roadmap",
            name: "roadmap",
            component: Roadmap
        },
        {
            path: "/team",
            name: "Team",
            component: Team
        },
        {
            path: "/faq",
            name: "FAQ",
            component: FAQ
        },
        {
            path: "/charity",
            name: "Charity",
            component: Charity
        },
        {
            path: "/giveaway",
            name: "Giveaway",
            component: Giveaway
        },
        {
          path: "/set-value",
          name: "setValue",
          component: SetValue
      },
        {
            path: "/profile",
            name: "profile",
            component: Profile
        }
    ],
    linkActiveClass: "active"
});